/* eslint-disable no-undef */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash'
import {
  flow, keys, reduce,
} from 'lodash/fp'
import React, {
  useState, useMemo, useEffect, useCallback,
} from 'react'
import { useTranslation } from 'react-i18next'
import { Formik } from 'formik'
import {
  useSupportTicket,
  useMetaDefinitions,
  useAttachment,
} from 'react-omnitech-api'
import { useAlert } from '../../hook'
import {
  transformEnquiryFormValidationSchema,
  fileHash,
} from '../../helpers'
import EnquiryView from './enquiry-view'
import EnquiryFrom from './enquiry-form'

function EnquiryController() {
  const formInitialValues = {
    email: '',
    phone: '',
    file: {
      size: 0,
    },
  }

  const requiredFields = useMemo(() => (
    flow(
      keys,
      reduce((result, current) => ({
        ...result,
        [current]: true,
      }), {}),
    )(formInitialValues)
  ), [formInitialValues])

  // prepare hook
  const alert = useAlert()
  const { t } = useTranslation()
  const { createSupportTicket } = useSupportTicket()
  const { fetchMetaDefinitions } = useMetaDefinitions()
  const { createAttachment, createS3DirectUploadImage, updateAttachment } = useAttachment()

  // internal states
  const [pageReady, setPageReady] = useState(true)
  const [formDisabled, setFormDisabled] = useState(false)
  const [isEmailSendSuccesfull, setIsEmailSendSuccesfull] = useState(false)
  const [metaDefinitions, setMetaDefinitions] = useState({})
  const [hash, setHash] = useState(undefined)
  const [storeFile, setStoreFile] = useState(undefined)
  const [storeAttachment, setStoreAttachment] = useState(undefined)
  const [alertSizeFile, setAlertSizeFile] = useState(undefined)

  // local variable
  const seoTitle = t('screens.enquiry.seo.title')

  /**
   * upLoadFile
   * @param {*} evt, event contain value from input image
   *
   */

  async function upLoadFile(e) {
    if (window.FileReader) {
      const file = e.target.files[0]
      if (file.size >= 1024 * (1024 * 4)) {
        setAlertSizeFile(t('screens.enquiry.errors.imageSizeValid'))
      } else {
        setAlertSizeFile(undefined)
        setStoreFile(file)
        const hasher = await fileHash(file)
        setHash(hasher)
      }
    }
  }
  /**
   * Handle Create Attachment Image
   * @param {*} values, object contain all value from input
   *
   */

  const handleAttachment = useCallback(async () => {
    if (!storeFile || !hash) return
    const params = {
      attachmentable_namespace: 'image',
      attachmentable_type: 'Temp',
      checksum: hash,
      content_type: storeFile.type,
      filename: storeFile.name,
    }
    try {
      const { attachment } = await createAttachment(params)
      return attachment
      // setStoreAttachment(attachment)
    } catch (error) {
      const generalError = _.get(error, 'generalError', {})
      alert.show(generalError.message)
    }
  }, [storeFile, hash])

  /**
   * HandleEnquiry
   * @param {*} values, object contain all value from input
   *
   */
  const handleSubmit = async (values, actions) => {
    setFormDisabled(true)
    alert.remove()
    const attachmnet = await handleAttachment()
    if (attachmnet && attachmnet.id) {
      updateAttachment(attachmnet.id)
      createS3DirectUploadImage(attachmnet, storeFile)
        .then(() => handleCreateSupportTicket(values, actions, attachmnet))
        .catch(() => {
          alert.show(t('screens.enquiry.errors.attachment', { context: 'cannotUpload' }))
          setFormDisabled(false)
          actions.setSubmitting(false)
        })
    } else {
      handleCreateSupportTicket(values, actions, attachmnet)
    }
  }

  async function handleCreateSupportTicket(values, actions, attachmnet) {
    const supportTicket = {
      meta: {
        name: values.name,
        email: values.email,
        phone: values.phone,
        subject_heading: values.subject,
      },
      attachment_uuids: attachmnet ? [attachmnet.uuid] : [''],
      details: values.details || '',
    }
    try {
      // TODO sent the data
      createSupportTicket(supportTicket)
      setIsEmailSendSuccesfull(true)
    } catch (error) {
      // TODO: handle error
      const generalError = _.get(error, 'generalError', {})
      alert.show(generalError.message)
      setIsEmailSendSuccesfull(false)
    } finally {
      actions.setSubmitting(false)
      setFormDisabled(false)
    }
  }

  const handleGetMetaDefinitions = () => {
    setPageReady(false)
    // metaModelNames
    const apiParams = {
      includes: [
        'meta_definition_value_list_items',
      ].join(','),
    }

    fetchMetaDefinitions(apiParams)
      .then((data) => {
        setMetaDefinitions(data.metaDefinitions)
      }).catch((error) => {
      // TODO: handle error
        console.error('handleGetMetaDefinitions error: ', error)
      }).finally(() => {
        setPageReady(true)
      })
  }

  useEffect(() => {
    handleGetMetaDefinitions()
  }, [])

  const removeAttachment = () => {
    const element = document.querySelector("form input[name='file']")
    element.value = null
    setStoreAttachment(undefined)
    setStoreFile(undefined)
  }

  const viewProps = {
    pageReady,
    isEmailSendSuccesfull,
    seoTitle,
  }
  const formPorps = {
    formDisabled,
    metaDefinitions,
    upLoadFile,
    requiredFields,
    storeAttachment,
    removeAttachment,
    storeFile,
    alertSizeFile,
  }

  return (
    <EnquiryView {...viewProps}>
      <Formik
        enableReinitialize
        initialValues={formInitialValues}
        validateOnChange
        validationSchema={transformEnquiryFormValidationSchema}
        onSubmit={handleSubmit}
      >
        <EnquiryFrom {...formPorps} />
      </Formik>
    </EnquiryView>
  )
}

export default EnquiryController
