import React from 'react'
import { useTranslation } from 'react-i18next'
import useStyles from './enquiry-style'
import IconPhone from '../../assets/icons/icon_phone.svg'
import IconEmail from '../../assets/icons/icon_envelope.svg'
import {
  Layout, Loading, MessageScreen, SEO,
} from '../../ui'

function EnquiryView({
  // the page will be ready after loading article data
  pageReady,
  children,
  isEmailSendSuccesfull,
  seoTitle,
}) {
  const styles = useStyles()
  const { t } = useTranslation()
  return (
    <Layout>
      {/* passing page specific data for SEO */}
      {/* TODO: add seo title */}
      <SEO title={seoTitle} />
      {pageReady ? (
        <div className={styles.containerFluid}>
          <div className={styles.container}>
            {isEmailSendSuccesfull ? (
              <MessageScreen
                title={t('screens.contact.messageSuccess.title')}
                message={t('screens.contact.messageSuccess.message')}
                button={{
                  text: t('screens.contact.messageSuccess.button'),
                  navigate: '/',
                }}
              />
            )
              : (
                <>
                  <h1 className={styles.titleEnquiryStyle}>{t('screens.contact.title')}</h1>
                  <div className={styles.boxContent}>
                    <div className={styles.boxForm}>
                      {children}
                    </div>
                    <div className={styles.boxInfo}>
                      <h5>{t('screens.contact.info.title')}</h5>
                      <p>
                        <img src={IconEmail} alt="email" />
                        <a href={`mailto:${t('screens.enquiry.form.email')}`}>
                          <span>{t('screens.enquiry.form.email')}</span>
                        </a>
                      </p>
                      <p>
                        <img src={IconPhone} alt="phone" />
                        <a href={`tel:+:${t('screens.enquiry.form.phone')}`}>
                          <span>{t('screens.enquiry.form.phone')}</span>
                        </a>
                      </p>
                    </div>
                  </div>
                </>
              )}
          </div>
        </div>
      ) : (
        <Loading />
      )}
    </Layout>
  )
}

export default EnquiryView
