import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  formStyle: {
    textAlign: 'left',
    maxWidth: '57rem',

  },
  messageStyle: {
    color: theme.colors.primary,
    marginBottom: '6rem',
    lineHeight: 1.25,

  },
  link: {
    color: theme.colors.text,
  },
  requireStyle: {
    color: theme.colors.bgDark,
    fontSize: '1.2rem',
    marginBottom: '2rem',
    lineHeight: 1.14,
    marginTop: '2.1rem',
    '&:before': {
      content: '"*"',
      color: theme.colors.alert,
      marginRight: '0.3rem',
    },
  },
  boxInfoMobile: {
    display: 'block',
    flex: '1 1 100%',
    textAlign: 'left',
    marginBottom: '6rem',
  },
  info: {
    display: 'flex',
    alignItems: 'baseline',
    lineHeight: 1.12,
    marginBottom: '1.2rem',
    '& a': {
      textDecoration: 'none',
      color: theme.colors.primary,
    },
    '& span': {
      lineHeight: 0,
      color: theme.colors.primary,
    },
    '& img': {
      margin: '0 1rem 0 0',
      lineHeight: 0,
    },
  },
  boxTitle: {
    fontSize: '1.6rem',
    fontWeight: 600,
    lineHeight: 1.25,
    marginBottom: '5rem',
    textTransform: 'uppercase',
  },
  boxInfoTilte: {
    fontSize: '1.6rem',
    fontWeight: 600,
    lineHeight: 1.25,
    marginBottom: '2rem',
    textTransform: 'uppercase',
  },
  groupInputStyle: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '1.5rem',
    '& div[class*="dropdown-0-2-21"]': {
      marginBottom: '1.2rem',
    },
  },
  inputContainerStyle: {
    minHeight: '4rem',
    marginTop: '1rem',
    '& label': {
      paddingBottom: '1.2rem',
    },
    '& input': {
      padding: '0.5rem 1rem',
      height: '3.7rem',
    },
  },
  inputFileStyle: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    alignContent: 'flex-end',
    '& div[class*="input"]': {
      width: '14rem',
    },
    '& label': {
      fontSize: '1.2rem',
      fontWeight: 600,
    },
  },
  removeStyle: {
    position: 'relative',
    width: '20rem',
    margin: 0,
    padding: '0 0 .8rem 1rem',
    height: '3.2rem',
    display: 'flex',
    alignItems: 'center',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '18rem',
    overflow: 'hidden',
    '& img': {
      top: '44%',
      position: 'absolute',
      right: 0,
      margin: 0,
      zIndex: 1,
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      right: 0,
      top: 0,
      width: '3.5rem',
      height: '4rem',
      zIndex: 2,
      background: 'linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 30%, rgba(255,255,255,1) 100%)',
    },
  },
  dropdownStyle: {
    marginBottom: '0',
    height: '8rem',
    '& label': {
      fontWeight: 600,
      fontSize: '1.2rem',
      lineHeight: '1.4rem',
    },
    '& > div': {
      marginTop: '3rem',
    },
  },
  btnSend: {
    width: '17rem',
    height: '5rem',
    textTransform: 'capitalize',
  },
  boxInfoTitle: {
    textTransform: 'uppercase',
  },
  attachInfo: {
    fontSize: 14,
    color: theme.colors.bgMediumDark,
  },
  boxFile: {
    height: '3.2rem',
  },
  errorStyle: {
    fontSize: '1.4rem',
    lineHeight: 1.14,
    color: theme.colors.alert,
    padding: '.8rem 0',
    marginBottom: 0,
  },
  [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
    formStyle: {
      marginRight: '0rem',
    },
    boxInfoTitle: {
      marginBottom: '2.8rem',
    },
    boxInfoMobile: {
      display: 'none',
    },
    inputContainerStyle: {
      width: 'calc(50% - 1.5rem)',
      marginTop: 0,
      minHeight: '4rem',
      '& label': {
        paddingBottom: '1.2rem',
      },
      '& input': {
        padding: '0.5rem 1rem',
        height: '3.7rem',
      },
    },
    groupInputStyle: {
      flexDirection: 'row',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      marginBottom: '1.5rem',
    },
    inputFileStyle: {
      justifyContent: 'flex-start',
      alignContent: 'flex-end',
      '& > input ': {
        width: 'inerehit',
      },
    },
    removeStyle: {
      width: '20rem',
      margin: '0',
      padding: '0.3rem 0 0.8rem 3.5rem',
      minHeight: '3.1rem',
    },
    dropdownStyle: {
      width: 'calc(50% - 1.5rem)',
      margin: 0,
      height: '4rem',
      '& label': {
        position: 'relative',
        paddingBottom: '1.4rem',
        display: 'block',
      },
      '& div': {
        marginTop: 0,
      },
    },
  },

}))

export default useStyles
