import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  containerFluid: {
    width: '100%',
    padding: [[30, 20, 40]],
  },
  container: {
    textAlign: 'center',
  },
  titleEnquiryStyle: {
    fontSize: '1.6rem',
    fontWeight: 600,
    lineHeight: 1.25,
    marginBottom: '3rem',
    textTransform: 'uppercase',
  },
  boxContent: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  boxForm: {
    flex: '1 1 100%',
  },
  boxInfo: {
    display: 'none',
    flex: '1 1 41%',
    textAlign: 'left',
    '& h5': {
      fontSize: '1.6rem',
      fontWeight: 600,
      lineHeight: 1.25,
      marginBottom: '2rem',
      textTransform: 'uppercase',
    },
    '& p': {
      display: 'flex',
      alignItems: 'baseline',
      lineHeight: 1.12,
      marginBottom: '1.8rem',
      '& a': {
        color: theme.colors.primary,
        textDecoration: 'none',
      },
      '& span': {
        lineHeight: 0,
      },
      '& img': {
        margin: '0 0.8rem 0 0.2rem',
        lineHeight: 0,
      },
    },
  },
  [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
    containerFluid: {
      padding: [[40, 10, 60]],
      width: '100%',
      textAlign: 'center',
    },
    container: {
      width: 1170,
      margin: [[0, 'auto']],
      display: 'flex',
      flexDirection: 'column',

    },
    titleEnquiryStyle: {
      marginBottom: '6rem',
    },
    boxContent: {
      flexDirection: 'row',
      width: '100%',
    },
    boxForm: {
      flex: '1 1 100%',
      paddingRight: '12.2rem',
    },
    boxInfo: {
      flex: '1 1 100%',
      paddingLeft: '1rem',
      display: 'block',
      '& h5': {
        marginBottom: '2.4rem',
      },
    },
  },
  [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
    boxForm: {
      flex: '1 1 59%',
      paddingRight: '12.2rem',
    },
    boxInfo: {
      flex: '1 1 41%',
      paddingLeft: '1rem',
      display: 'block',
    },
  },
}))

export default useStyles
