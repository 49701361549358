import _ from 'lodash'
import React, { useMemo } from 'react'
import { useTheme } from 'react-jss'
import { useTranslation } from 'react-i18next'
import {
  Form,
  useFormikContext,
} from 'formik'
import useStyles from './enquiry-form-style'
import {
  customStylesOnlyText,
} from '../../ui/dropdown/custom-styles'
import { Button, Input, Dropdown } from '../../ui'
import IconPhone from '../../assets/icons/icon_phone.svg'
import IconEmail from '../../assets/icons/icon_envelope.svg'
import clearIcon from '../../assets/icons/icon_clear_grey.svg'

const EnquiryFromView = ({
  formDisabled,
  metaDefinitions,
  requiredFields,
  upLoadFile,
  removeAttachment,
  storeFile,
  alertSizeFile,
}) => {
  const {
    submitForm,
    values,
    setFieldValue,
  } = useFormikContext()

  const handleSubmitForm = () => {
    submitForm(values)
  }

  const styles = useStyles()
  const theme = useTheme()
  const { t } = useTranslation()

  const metaDefinitionValueList = useMemo(() => (
    _.find(metaDefinitions, { fieldKey: 'subject_heading' })
  ), [metaDefinitions])

  const subjectOptions = useMemo(() => {
    if (_.isEmpty(metaDefinitionValueList)) return []
    // const subject = _.chain(metaDefinitionValueList)
    //   .get('metaDefinitionValueListItems')
    //   .filter({ enabled: true })
    //   .value()
    const subject = _.filter(_.get(metaDefinitionValueList, 'metaDefinitionValueListItems'), { enabled: true })
    return subject.map((item) => (
      {
        label: item.name,
        value: item.value,
      }
    ))
  }, [metaDefinitionValueList])
  return (
    <div className={styles.formStyle}>
      <p className={styles.messageStyle}>{t('screens.contact.form.message')}</p>
      <div className={styles.boxInfoMobile}>
        <h4 className={styles.boxTitle}>{t('screens.contact.info.title')}</h4>
        <p className={styles.info}>
          <img src={IconEmail} alt="email" />
          <a className={styles.link} href={`mailto:${t('screens.enquiry.form.email')}`}>
            <span>{t('screens.enquiry.form.email')}</span>
          </a>
        </p>
        <p className={styles.info}>
          <img src={IconPhone} alt="phone" />
          <a className={styles.link} href={`tel:+:${t('screens.enquiry.form.phone')}`}>
            <span>{t('screens.enquiry.form.phone')}</span>
          </a>
        </p>
      </div>
      <h5 className={styles.boxInfoTitle}>{t('screens.contact.form.title')}</h5>
      <Form noValidate autoComplete="off">
        <div className={styles.groupInputStyle}>
          <Dropdown
            className={styles.dropdownStyle}
            placeholder="Subject"
            required
            customStyles={customStylesOnlyText}
            options={subjectOptions}
            onChange={(option) => setFieldValue('subject', option.value)}
            label={t('screens.contact.form.subject')}
            borderColor={theme.colors.bgDark}
            values={(values.subject)
              ? _.filter(subjectOptions, { value: values.subject })[0] : null}
            name="subject"
            isSearchable={false}
          />
          <Input
            formik
            uppercase={false}
            containerClassName={styles.inputContainerStyle}
            label={t('screens.contact.form.refOrder')}
            name="name"
          />
        </div>
        <div className={styles.groupInputStyle}>
          <Input
            formik
            uppercase={false}
            containerClassName={styles.inputContainerStyle}
            required={requiredFields.phone}
            label={t('screens.contact.form.phone')}
            name="phone"
          />
          <Input
            formik
            uppercase
            required={requiredFields.email}
            containerClassName={styles.inputContainerStyle}
            label={t('screens.contact.form.email')}
            name="email"
          />
        </div>
        <div className={styles.inputFileStyle}>
          <Input
            fileInput
            uppercase={false}
            type="file"
            width="calc(25% - 1.5rem)"
            placeholder={t('screens.contact.form.buttons.attach')}
            label={t('screens.contact.form.attach')}
            name="file"
            onChange={upLoadFile}
          />
          {
            storeFile ? (
              <div className={styles.boxFile}>
                <Button
                  className={styles.removeStyle}
                  onClick={removeAttachment}
                  iconImage={clearIcon}
                  text={_.get(storeFile, 'name')}
                />
              </div>
            ) : (
              <div className={styles.boxFile}>
                <p className={styles.removeStyle}>{t('screens.contact.form.info')}</p>
              </div>
            )
          }
        </div>
        { alertSizeFile && <p className={styles.errorStyle}>{alertSizeFile}</p>}
        <p className={styles.attachInfo}>{t('screens.contact.form.attachInfo')}</p>
        <Input
          formik
          label={t('screens.contact.form.messageTitle')}
          name="details"
          component="textarea"
          type="text"
          rows="13"
        />
        <p className={styles.requireStyle}>{t('screens.login.required')}</p>
        <div>
          <Button
            dark
            className={styles.btnSend}
            text={t('screens.contact.form.buttons.send')}
            disabled={formDisabled}
            onClick={handleSubmitForm}
          />
        </div>
      </Form>
    </div>
  )
}

export default EnquiryFromView
